.res{
    display: none;
}
@media (min-width: 600px) {
    .res{
        position: fixed;
        display: block;
        left: 20px;
        bottom: 70px;
        z-index: 10000;
        &__list{
            padding: 0;
            &__el{
                list-style-type: none;
                font-size: 0;
                padding: 5px;
                background-color: $c-hover;
                border-radius: 10px;
                &:hover{
                    cursor: pointer;
                    transform: rotate(5deg);
                }
                &:last-child{
                    margin: 10px 0 0 0;
                }
                .res__logo{
                    width: 35px;
                    height: auto;
                }
            }
        }
    }
}