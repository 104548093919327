$c-bleu-fonce:#0B2545;
$c-bleu-clair:#8DA9C4;
$c-blanc-casse:#F3F3F3;
$c-blanc:#FFFFFF;
$c-noir:#000000;
$c-hover: var(--c-hover);

$size-text-phone: 18px;
$size-text-desktop: 20px;

$size-title-phone: 44px;
$size-title-tablet: 68px;
$size-title-desktop: 95px;

$size-title-desktop-princ: 167px;
$size-title-tablet-princ: 132px;
$size-title-phone-princ: 86px;

$size-title-phone-comp: 31px;
$size-title-phone-text:28px;
$size-title-desktop-text:35px;