* {
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Baloo 2", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
  background-color: #F3F3F3;
  overflow-x: hidden;
}

.section {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 20px 20px 75px 20px;
  background-color: #F3F3F3;
}
.section__intro {
  padding: 0 0 75px 0;
  height: 100vh;
  width: 100vw;
}
.section--clair {
  background-color: #FFFFFF;
}
.section__comp {
  display: flex;
  flex-direction: column;
}

.title {
  color: #0B2545;
  font-size: 44px;
  font-family: "teko", sans-serif;
  grid-column: 1/-1;
}
.title__princ {
  font-size: 86px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title__princ span {
  line-height: 0.7;
}
.title--comp {
  font-size: 31px;
}
.title--projet {
  font-size: 31px;
  color: #FFFFFF;
}

.container__img {
  grid-column: 2/-2;
  aspect-ratio: 1/1;
}

.img--avatar {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.container__text {
  grid-column: 1/-1;
}
.container__text .text {
  padding-bottom: 15px;
}
.container__text .text__lien {
  color: var(--c-hover);
  cursor: pointer;
}
.container__text .text__lien:hover,
.container__text .text__lien :focus {
  text-decoration: underline;
  cursor: pointer;
}

.eti {
  display: flex;
  background-color: var(--c-hover);
  position: absolute;
  padding: 5px;
  border-radius: 10px;
  rotate: -15deg;
  transition: 0.2s;
}
.eti:hover {
  rotate: -20deg;
  transition: 0.2s;
}
.eti--1 {
  top: 3%;
  left: -2%;
}
.eti--2 {
  bottom: 12%;
  right: -5%;
}
.eti--2:hover {
  rotate: -10deg;
}

.container {
  display: flex;
}
.container__title {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container__comp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comp__el {
  border: 3px var(--c-hover) solid;
  border-radius: 25px;
  padding: 20px;
  margin-bottom: 30px;
  width: 330px;
}
.comp__el:last-child {
  margin: 0;
}

.svg-logo {
  width: auto;
  height: 80px;
  margin: 0 10px 20px 0;
  fill: #0B2545;
}

.comp__logo {
  display: flex;
  align-items: baseline;
}

.projet {
  grid-column: 1/-1;
  aspect-ratio: 1/1;
  border-radius: 25px;
  position: relative;
}
.projet .img--proj {
  width: 100%;
  cursor: none;
  border-radius: 25px;
}
.projet__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 98.5%;
  width: 100%;
  padding: 20px;
  transition: 0.2s;
  border-radius: 25px;
  position: absolute;
  top: 0;
  left: 0;
  color: #FFFFFF;
  background: linear-gradient(to top, var(--c-hover), rgba(255, 255, 255, 0));
}
.projet__lien {
  color: #000000;
}

@media (min-width: 600px) and (max-width: 900px) {
  .projet {
    grid-column: 2/-2;
  }
}
@media (min-width: 901px) and (max-width: 1199px) {
  .section {
    grid-template-columns: repeat(9, 1fr);
    gap: 20px;
    padding: 50px 20px;
  }

  .projet {
    grid-column: 3/-3;
  }
  .projet__content {
    height: 99.5%;
  }

  .title {
    margin-bottom: 35px;
    font-size: 68px;
  }
  .title__princ {
    font-size: 132px;
  }
  .title--comp {
    margin-bottom: 15px;
    font-size: 31px;
  }
  .title--projet {
    color: #FFFFFF;
    margin-bottom: 10px;
  }

  .eti--1 {
    left: -27%;
    top: -14%;
  }
  .eti--2 {
    bottom: -3%;
    right: -27%;
  }

  .container__text {
    grid-column: 2/7;
  }
  .container__img {
    grid-column: 7/-2;
  }
}
@media (min-width: 901px) {
  .section {
    min-height: 100vh;
    height: auto;
  }
  .section__pres {
    height: 100vh;
  }
}
@media (min-width: 1200px) {
  body {
    font-size: 20px;
  }

  .section {
    padding: 50px 20px 75px 20px;
    width: 100vw;
    grid-template-columns: repeat(11, 1fr);
  }
  .section__comp {
    display: grid;
  }
  .section__pres {
    grid-template-rows: repeat(3, 1fr);
  }

  .title {
    font-size: 95px;
    grid-column: 2/-1;
  }
  .title__princ {
    font-size: 167px;
  }
  .title--comp {
    font-size: 31px;
  }
  .title--projet {
    font-size: 31px;
    color: #FFFFFF;
  }

  .eti--1 {
    top: -28%;
    left: -43%;
  }
  .eti--2 {
    bottom: -13%;
    right: -45%;
  }

  .container__img {
    grid-column: 8/-2;
    align-self: center;
  }
  .container__text {
    grid-column: 2/8;
    align-self: center;
  }
  .container__comp {
    grid-column: 3/-3;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    align-items: stretch;
  }

  .comp__el {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  .projet--hw {
    grid-column: 2/5;
  }
  .projet--rux {
    grid-column: 5/8;
  }
  .projet--dp {
    grid-column: 8/-2;
  }
  .projet__content {
    height: 97.5%;
    opacity: 0;
    transition: 0.2s;
  }
  .projet__content:hover {
    opacity: 1;
    transition: 0.2s;
    background: linear-gradient(to top, var(--c-hover), rgba(255, 255, 255, 0));
  }
}
header {
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 20px;
  position: fixed;
  box-sizing: border-box;
  z-index: 1000;
}

.burger {
  display: none;
}

.menu .liste {
  display: flex;
}
.menu .liste__el__liens {
  display: block;
  color: #0B2545;
  font-size: 20px;
  padding: 10px 25px;
  border-radius: 50px;
  transition: 0.2s;
  margin: 0 5px;
  cursor: pointer;
}
.menu .liste__el__liens:hover {
  color: #0B2545;
  background: var(--c-hover);
  cursor: pointer;
}
.menu .liste__el__liens--active {
  color: #0B2545;
  background: var(--c-hover);
}

@media (max-width: 1200px) {
  .burger {
    display: block;
    cursor: pointer;
    z-index: 10;
  }
  .burger__line {
    transition: 0.2s;
    width: 30px;
    height: 3px;
    background: var(--c-hover);
    margin: 6px 0;
  }
  .burger--active {
    position: relative;
  }
  .burger--active .burger__line {
    position: absolute;
    right: 10px;
    top: -10px;
  }
  .burger--active :nth-child(1) {
    transition: 0.2s;
    transform: translate(50%, 0%) rotate(45deg);
  }
  .burger--active :nth-child(2) {
    transition: 0.2s;
    opacity: 0;
  }
  .burger--active :last-child {
    transition: 0.2s;
    transform: translate(50%, 0%) rotate(-45deg);
  }

  .menu {
    z-index: 9;
    height: 0;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    transition: 0.2s;
    overflow: hidden;
  }
  .menu .liste {
    display: block;
    width: fit-content;
    margin: 40px auto 0 auto;
    text-align: center;
    transition: 0.5s;
    opacity: 0;
  }
  .menu .liste__el__liens {
    margin-bottom: 12px;
  }
  .menu--active {
    height: 250px;
  }
  .menu--active .liste {
    opacity: 1;
  }
}
.canvas {
  display: block;
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  overflow-x: hidden;
}

.section__stage {
  width: 100vw;
  height: auto;
  padding: 100px 20px;
}

.title--stage {
  line-height: 1;
}
.title--text {
  font-size: 28px;
  font-weight: 400;
}

.container__text--stage {
  grid-column: 1/-1;
}

@media (min-width: 900px) {
  .container__text--stage {
    grid-column: 2/-2;
  }

  .title--text {
    margin-bottom: 10px;
  }
}
@media (min-width: 1200px) {
  .container__text--stage {
    grid-column: 2/-4;
  }

  .title--stage {
    margin: 50px 0;
    line-height: 1;
  }
  .title--text {
    font-size: 35px;
    margin-bottom: 10px;
  }
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.footer .credits a {
  color: var(--c-hover);
  text-decoration: none;
}
.footer .credits a:hover, .footer .credits a:focus {
  text-decoration: underline;
}

.container__text--pres,
.comp__el--adobe,
.comp__el--web,
.comp__el--figma,
.comp__el--gpt,
.title__anim {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.container__title {
  opacity: 0;
  transition: 0.5s ease-out;
}

.projet--hw,
.projet--rux,
.projet--dp {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.res {
  display: none;
}

@media (min-width: 600px) {
  .res {
    position: fixed;
    display: block;
    left: 20px;
    bottom: 70px;
    z-index: 10000;
  }
  .res__list {
    padding: 0;
  }
  .res__list__el {
    list-style-type: none;
    font-size: 0;
    padding: 5px;
    background-color: var(--c-hover);
    border-radius: 10px;
  }
  .res__list__el:hover {
    cursor: pointer;
    transform: rotate(5deg);
  }
  .res__list__el:last-child {
    margin: 10px 0 0 0;
  }
  .res__list__el .res__logo {
    width: 35px;
    height: auto;
  }
}