header{
    width: 100vw;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 20px;
    position: fixed;
    box-sizing: border-box;
    z-index: 1000;
}
.burger{
    display: none;
}
.menu{
    .liste{
        display: flex;
        &__el{
            &__liens{
                display: block;
                color: $c-bleu-fonce;
                font-size: 20px;
                padding: 10px 25px;
                border-radius: 50px;
                transition: 0.2s;
                margin: 0 5px;
                cursor: pointer;
                &:hover{
                    color: $c-bleu-fonce;
                    background: $c-hover;
                    cursor: pointer;
                }
                &--active{
                    color: $c-bleu-fonce;
                    background: $c-hover;
                }
            }
        }
    }
}


@media (max-width: 1200px){
    .burger{
        display: block;
        cursor: pointer;
        z-index: 10;
        &__line{
            transition: 0.2s;
            width: 30px;
            height: 3px;
            background: $c-hover;
            margin: 6px 0;
        }
        &--active{
            position: relative;
            .burger__line{
                position: absolute;
                right: 10px;
                top: -10px;
            }
        }
        &--active :nth-child(1){
            transition: 0.2s;
            transform: translate(50%, 0%) rotate(45deg);

        }
        &--active :nth-child(2){
            transition: 0.2s;
            opacity: 0;
            
        }
        &--active :last-child{
            transition: 0.2s;
            transform: translate(50%, 0%) rotate(-45deg);
            
        }
    }
    .menu{
        z-index: 9;
        height: 0;
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        width: 100%;
        background: rgba($color: $c-blanc , $alpha: 0.9);
        transition: 0.2s;
        overflow: hidden;
        .liste{
            display: block;
            width: fit-content;
            margin: 40px auto 0 auto;
            text-align: center;
            transition: 0.5s;
            opacity: 0;
            &__el{
                &__liens{
                    margin-bottom: 12px;
                }
            }
        }
        &--active{
            height: 250px;
            .liste{
                opacity: 1;
            }
        }
    }
}