*{
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Baloo 2", sans-serif;
  font-size: $size-text-phone;
  line-height: 1.5;
  color: $c-noir;
  background-color: $c-blanc-casse;
  overflow-x: hidden;
}

.section{
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 20px 20px 75px 20px;
  background-color: $c-blanc-casse;

  &__intro{
    padding: 0 0 75px 0;
    height: 100vh;
    width: 100vw;
  }

  &--clair{
    background-color: $c-blanc;
  }

  &__comp{
    display: flex;
    flex-direction: column;
  }
}

.title {
  color: $c-bleu-fonce;
  font-size: $size-title-phone;
  font-family: "teko", sans-serif;
  grid-column: 1 / -1;

  &__princ{
    font-size: $size-title-phone-princ;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span{
      line-height: 0.7;
    }
  }

  &--comp{
    font-size: $size-title-phone-comp;
  }

  &--projet{
    font-size: $size-title-phone-comp;
    color: $c-blanc;
  }
}

.container__img{
  grid-column: 2 / -2;
  aspect-ratio: 1 / 1;
}

.img{

  &--avatar{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.container__text{
  grid-column: 1 / -1;

  .text{
    padding-bottom: 15px;

    &__lien{
      color: $c-hover;
      cursor: pointer;

      &:hover,
      :focus{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.eti{
  display: flex;
  background-color: $c-hover;
  position: absolute;
  padding: 5px;
  border-radius: 10px;
  rotate: -15deg;
  transition: 0.2s;

  &:hover{
    rotate: -20deg;
    transition: 0.2s;
  }

  &--1{
    top: 3%;
    left: -2%;
  }
  &--2{
    bottom: 12%;
    right: -5%;
    &:hover{
      rotate: -10deg;
    }
  }
}

.container{
  display: flex;
  &__title{
    width: 300px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.container__comp{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comp__el{
  border: 3px $c-hover solid;
  border-radius: 25px;
  padding: 20px;
  margin-bottom: 30px;
  width: 330px;

  &:last-child{
    margin: 0;
  }
}

.svg-logo{
  width: auto;
  height: 80px;
  margin: 0 10px 20px 0;
  fill: $c-bleu-fonce;
}

.comp__logo{
  display: flex;
  align-items: baseline;
}

.projet{
  grid-column: 1 / -1;
  aspect-ratio: 1 / 1;
  border-radius: 25px;
  position: relative;

  .img--proj{
    width: 100%;
    cursor: none;
    border-radius: 25px;
  }

  &__content{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 98.5%;
    width: 100%;
    padding: 20px;
    transition: 0.2s;
    border-radius: 25px;
    position: absolute;
    top: 0;
    left: 0 ;
    color: $c-blanc;
    background: linear-gradient(to top, $c-hover, rgba(255, 255, 255, 0));
  }
  &__lien{
    color: $c-noir;
  }
}
@media (min-width: 600px) and (max-width:900px){
  .projet{
    grid-column: 2 / -2;
  }
}
@media (min-width: 901px) and (max-width: 1199px){
  .section{
    grid-template-columns: repeat(9, 1fr);
    gap: 20px;
    padding: 50px 20px;
  }
  .projet{
    grid-column: 3 / -3;
    &__content{
      height: 99.5%;
    }
  }
  .title{
    margin-bottom: 35px;
    font-size: $size-title-tablet;

    &__princ{
      font-size: $size-title-tablet-princ;
    }

    &--comp{
      margin-bottom: 15px;
      font-size: $size-title-phone-comp;
    }

    &--projet{
      color: $c-blanc;
      margin-bottom: 10px;
    }
  }
  .eti{
    &--1{
      left: -27%;
      top: -14%;
    }
    &--2{
      bottom: -3%;
      right: -27%;
    }
  }

  .container{
    &__text{
      grid-column: 2 / 7;
    }
    &__img{
      grid-column: 7 / -2;
    }
  }
}
@media(min-width: 901px){
  .section{
    min-height: 100vh;
    height: auto;
    &__pres{
      height: 100vh;
    }
  }
}
@media (min-width: 1200px){
  body{
    font-size: $size-text-desktop;
  }

  .section{
    padding: 50px 20px 75px 20px;
    width: 100vw;
    grid-template-columns: repeat(11, 1fr);

    &__comp{
      display: grid;
    }

    &__pres{
      grid-template-rows: repeat(3, 1fr);
    }
  }

  .title{
    font-size: $size-title-desktop;
    grid-column: 2/-1;

    &__princ{
      font-size: $size-title-desktop-princ;
    }

    &--comp{
      font-size: $size-title-phone-comp;
    }
  
    &--projet{
      font-size: $size-title-phone-comp;
      color: $c-blanc;
    }
  }

  .eti{
  
    &--1{
      top: -28%;
      left: -43%;
    }
    &--2{
      bottom: -13%;
      right: -45%;
    }
  }
  .container{

    &__img{
      grid-column: 8 / -2;
      align-self: center;
    }

    &__text{
      grid-column: 2 / 8;
      align-self: center;
    }

    &__comp{
      grid-column: 3 / -3;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;
      align-items: stretch;
    }
  }
  
  .comp__el{
  width: 100%;
  margin: 0;
  padding: 20px;
  }

  .projet{

    &--hw{
      grid-column: 2/5;
    }
    &--rux{
      grid-column: 5/8;
    }
    &--dp{
      grid-column: 8/-2;
    }

    &__content{
      height: 97.5%;
    opacity: 0;
    transition: 0.2s;

    &:hover{
      opacity: 1;
      transition: 0.2s;
      background: linear-gradient(to top, $c-hover, rgba(255, 255, 255, 0));
    }
    }
  }
}