.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    .credits{
        a{
            color: $c-hover;
            text-decoration: none;
            &:hover,&:focus{
                text-decoration: underline;
            }
        }
    }
}